<template>
     <div class="initiate_inquiry">
          <div class="inquiry_header">
               <div class="header_bg" :style="{ backgroundImage: `url(${header_bg})` }"></div>
          </div>

          <div class="initiate_inquiry_z">
               <div class="init_main">
                    <div class="bd1 flex-col">
                         <div class="bd2 flex-col">
                              <div class="outer1 flex-row justify-between">
                                   <div class="layer1 flex-col"></div>
                                   <span class="info1">询盘需求</span>
                              </div>
                              <div class="outer2 flex-row" id="title">
                                   <div class="bd3 flex_row_start_start">
                                        <div class="flex_row_start_center bd31">
                                             <span class="word2">*</span>
                                             <span class="info2">询盘标题：</span>
                                        </div>

                                        <div :class="{ validate: validate.title }">
                                             <div class="bd4 flex_row_start_center vBorder">
                                                  <input type="text" v-model="inqDTO.title" maxlength="50"
                                                       @input="inputEvent('title')">
                                                  <span class="word3">{{ inqDTO.title.length }}/50</span>
                                             </div>
                                             <span class="error_msg">{{ validate.title }}</span>
                                        </div>

                                   </div>
                              </div>
                              <span class="info3">产品清单</span>
                              <div class="outer3 flex-col">
                                   <div class="bd6 flex-col">
                                        <div class="outer30 flex-col justify-between">
                                             <div class="block2 flex-row justify-between">
                                                  <div class="main1">
                                                       <span class="word29">*</span>
                                                       <span class="word30">产品名称</span>
                                                  </div>
                                                  <span class="word31">规格/型号</span>
                                                  <span class="txt16">品牌</span>
                                                  <div class="main2">
                                                       <span class="info20">*</span>
                                                       <span class="word32">购买数量</span>
                                                  </div>
                                                  <div class="main3">
                                                       <span class="word33">*</span>
                                                       <span class="word34">期望单价(元)</span>
                                                  </div>
                                             </div>
                                             <div class="block3 flex-col" v-for="(pro, proIdx) in inqDTO.productList"
                                                  :id="`rowtab${proIdx}`">
                                                  <div class="layer16 flex-row">
                                                       <div :class="{ validate: validate.productName.index == proIdx }">
                                                            <div class="section12 flex-col vBorder">
                                                                 <input type="text" v-model="pro.productName"
                                                                      @input="inputEvent('productName', proIdx)"
                                                                      maxlength="30">
                                                            </div>
                                                            <span class="error_msg">{{ validate.productName.msg }}</span>
                                                       </div>

                                                       <div class="section13 flex-col">
                                                            <input type="text" v-model="pro.spec" maxlength="50">
                                                       </div>
                                                       <div class="section13 flex-col">
                                                            <input type="text" v-model="pro.brandName" maxlength="30">
                                                       </div>

                                                       <div :class="{ validate: validate.buyNum.index == proIdx || validate.unit.index == proIdx }"
                                                            style="margin-left: 57px;">
                                                            <div class="section15 flex-col vBorder">
                                                                 <div class="layer17 flex_row_between_center">
                                                                      <input type="number" v-model="pro.buyNum"
                                                                           @input="inputEvent('buyNum', proIdx)"
                                                                           @blur="blurEvent(pro, 'buyNum')">
                                                                      <div class="flex_row_between_center con1">
                                                                           <el-select v-model="pro.unitName" class="m-2"
                                                                                placeholder="请选择" size="small">
                                                                                <el-option
                                                                                     v-for="(item, index) in unitList"
                                                                                     :key="index" :label="item"
                                                                                     :value="item">
                                                                                </el-option>
                                                                           </el-select>
                                                                      </div>
                                                                      <div class="layer18" v-show="pro.unitName == '其他'">
                                                                           <input type="text" v-model="pro.unit"
                                                                                maxlength="5">
                                                                      </div>
                                                                 </div>
                                                            </div>
                                                            <span
                                                                 class="error_msg">{{ validate.buyNum.msg || validate.unit.msg }}</span>
                                                       </div>

                                                       <div class="container1">
                                                            <div :class="{ validate: validate.price.index == proIdx }">
                                                                 <div class="flex-row ">
                                                                      <div class="section16 flex-col vBorder">
                                                                           <input type="number" placeholder="最小值"
                                                                                v-model="pro.priceMin"
                                                                                @input="inputEvent('price', proIdx)"
                                                                                @blur="blurEvent(pro, 'priceMin')">
                                                                      </div>
                                                                      <span class="info22">~</span>
                                                                      <div class="section17 flex-col vBorder">
                                                                           <input type="number" placeholder="最大值"
                                                                                v-model="pro.priceMax"
                                                                                @input="inputEvent('price', proIdx)"
                                                                                @blur="blurEvent(pro, 'priceMax')">
                                                                      </div>
                                                                 </div>
                                                                 <span class="error_msg">{{ validate.price.msg }}</span>
                                                            </div>
                                                       </div>

                                                       <div class="section18" v-show="proIdx > 0">
                                                            <img src="@/assets/inquiry/inq_icon9.png"
                                                                 @click="removeProPanel(proIdx)">
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </div>

                              <div class="outer5 flex-col" @click="addProductPanel"
                                   v-show="inqDTO.productList && inqDTO.productList.length <= 30">
                                   <span class="info6">+&nbsp;添&nbsp;加</span>
                              </div>
                              <div class="outer6g flex_row_start_start">
                                   <div class="section1g">
                                        <span class="word6g">*</span>
                                        <span class="word7g">期望收货日期:</span>
                                   </div>
                                   <div class="section2g" id="expectedDeliverDate">
                                        <div :class="{ validate: validate.expectedDeliverDate }">
                                             <div class="flex-col vBorder section21g">
                                                  <el-date-picker v-model="inqDTO.expectedDeliverDate" type="date"
                                                       placeholder="请选择" :disabled-date="disabledDate"
                                                       :shortcuts="shortcuts"
                                                       @change="inputEvent('expectedDeliverDate')">
                                                  </el-date-picker>
                                             </div>
                                             <span class="error_msg">{{ validate.expectedDeliverDate }}</span>
                                        </div>
                                   </div>

                                   <div class="section3g">
                                        <span class="word8g">*</span>
                                        <span class="word9g">报价截止时间:</span>
                                   </div>
                                   <div class="section2g flex-col" id="priceEndTime">
                                        <div :class="{ validate: validate.priceEndTime }">
                                             <div class="flex-col vBorder section21g">
                                                  <el-date-picker v-model="inqDTO.priceEndTime" type="datetime"
                                                       placeholder="请选择" :disabled-date="disabledDate"
                                                       :shortcuts="shortcuts" @change="inputEvent('priceEndTime')">
                                                  </el-date-picker>
                                             </div>
                                             <span class="error_msg">{{ validate.priceEndTime }}</span>
                                        </div>
                                   </div>
                              </div>
                              <div class="outer8 flex-row">
                                   <span class="info8">指定配送方式：</span>
                                   <div class="flex_row_start_center " style="margin-left: 20px;cursor: pointer;"
                                        @click="checkRadio('deliver', 1, '买方自提')">
                                        <img src="@/assets/inquiry/inq_icon5.png" v-if="inqDTO.deliverType == 1" />
                                        <div class="outer10 flex-col" v-else></div>
                                        <span class="txt2">买方自提</span>
                                   </div>

                                   <div class="flex_row_start_center" style="margin-left: 30px;cursor: pointer;"
                                        @click="checkRadio('deliver', 2, '供方承运')">
                                        <img src="@/assets/inquiry/inq_icon5.png" v-if="inqDTO.deliverType == 2" />
                                        <div class="outer10 flex-col" v-else></div>
                                        <span class="txt2">供方承运</span>
                                   </div>

                                   <div class="flex_row_start_center" style="margin-left: 30px;cursor: pointer;">
                                        <div class="flex_row_start_center" @click="checkRadio('deliver', 3)">
                                             <img src="@/assets/inquiry/inq_icon5.png" v-if="inqDTO.deliverType == 3" />
                                             <div class="outer10 flex-col" v-else></div>
                                             <span class="txt2">其他</span>
                                        </div>
                                        <div :class="{ validate: validate.deliverTypeName }" style="margin-left: 14px;">
                                             <div class="outer12 flex-col vBorder"
                                                  :class="{ disable_input: inqDTO.deliverType != 3 }">
                                                  <input type="text" v-model="inqDTO.deliverTypeName"
                                                       :disabled="inqDTO.deliverType != 3" v-show="inqDTO.deliverType == 3"
                                                       maxlength="20" @input="inputEvent('deliverTypeName')">
                                             </div>
                                             <span class="error_msg">{{ validate.deliverTypeName }}</span>
                                        </div>

                                   </div>

                              </div>
                              <div class="outer13 flex-row justify-between">
                                   <span class="word13">详情描述：</span>
                                   <div class="bd5 flex-col">
                                        <div class="section5 flex-row">
                                             <textarea name="" id="" cols="30" rows="9"
                                                  placeholder="请输入您对产品功能、规格、使用场景或其他方面的要求" v-model="inqDTO.description"
                                                  maxlength="500"></textarea>
                                        </div>
                                        <div class="section6 flex-row">
                                             <span class="word14">{{ inqDTO.description.length }}/500</span>
                                        </div>
                                   </div>
                              </div>

                              <div class="outer_14-1">
                                   <div class="outer14 flex-row justify-between">
                                        <span class="txt3">参考文件：</span>
                                        <div class="flex-row" :class="{ disabledUpload: fileList.length >= 3 }">
                                             <label for="upload">
                                                  <div class="outer15 flex-col">
                                                       <span class="info10">+&nbsp;添加附件</span>
                                                  </div>
                                             </label>
                                             <input type="file" name="" id="upload" size="1"
                                                  accept="image/png,image/jpeg,image/jpg,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,.doc,.docx,.xls,.xlsx,.pdf"
                                                  @change="changeFile" multiple ref="inputTab">
                                             <span class="word15">（支持添加doc、docx、xls、xlsx、pdf、jpg、jpeg、png格式的文件）</span>
                                        </div>

                                   </div>
                                   <div class="outer16 flex-row" v-for="(file, fileIdx) in fileList" :key="fileIdx">
                                        <img class="label5" src="@/assets/inquiry/inq_icon4.png"
                                             v-if="file.type == 'img'" />

                                        <img class="label5" src="@/assets/inquiry/excel.png"
                                             v-else-if="file.type == 'xlsx' || file.type == 'xls'" />

                                        <img class="label5" src="@/assets/inquiry/inq_icon10.png" v-else>
                                        <span
                                             class="word16">{{ file.type == 'img' ? `图片${fileIdx + 1}.${file.suffix}` : `文档${fileIdx + 1}.${file.suffix}` }}</span>

                                        <span class="txt4" @click="clearFileList(fileIdx)">删除</span>
                                   </div>
                              </div>

                              <div class="outer18 flex-row justify-between">
                                   <div class="group2 flex-col"></div>
                                   <span class="txt7">交易信息</span>
                              </div>
                              <div class="outer19 flex_row_start_center">
                                   <div class="box1">
                                        <span class="txt8">*</span> <span class="txt9">结算方式：</span>
                                   </div>
                                   <div class="box2g flex-row" @click="checkRadio('bill', 1, '定金发货')">
                                        <img src="@/assets/inquiry/inq_icon5.png" v-if="inqDTO.billType == 1">
                                        <div class="box3 flex-col" v-else></div>
                                        <span class="info11">定金发货</span>
                                   </div>

                                   <div class="box2g flex-row" @click="checkRadio('bill', 2, '全额付款')">
                                        <img src="@/assets/inquiry/inq_icon5.png" v-if="inqDTO.billType == 2">
                                        <div class="box3 flex-col" v-else></div>
                                        <span class="info11">全额付款</span>
                                   </div>

                                   <div class="box2g flex-row" @click="checkRadio('bill', 3, '分期付款')">
                                        <img src="@/assets/inquiry/inq_icon5.png" v-if="inqDTO.billType == 3">
                                        <div class="box3 flex-col" v-else></div>
                                        <span class="info11">分期付款</span>
                                   </div>

                                   <div class="box2g flex_row_start_center">
                                        <div class="flex_row_start_center" @click="checkRadio('bill', 4)">
                                             <img src="@/assets/inquiry/inq_icon5.png" v-if="inqDTO.billType == 4">
                                             <div class="box3 flex-col" v-else></div>
                                             <span class="info11">其他</span>
                                        </div>

                                        <div :class="{ validate: validate.billTypeName }">
                                             <div class="box6 flex-col vBorder"
                                                  :class="{ disable_input: inqDTO.billType != 4 }">
                                                  <input type="text" v-model="inqDTO.billTypeName"
                                                       :disabled="inqDTO.billType != 4" v-show="inqDTO.billType == 4"
                                                       maxlength="50" @input="inputEvent('billTypeName')">
                                             </div>
                                             <span class="error_msg">{{ validate.billTypeName }}</span>
                                        </div>


                                   </div>
                              </div>
                              <div class="outer20 flex-row">
                                   <div class="box1">
                                        <span class="txt8">*</span>
                                        <span class="txt9">发票要求：</span>
                                   </div>

                                   <div class="box2g flex_row_start_center" @click="checkRadio('invoice', 1)">
                                        <img src="@/assets/inquiry/inq_icon5.png" v-if="inqDTO.invoiceType == 1" />
                                        <div class="box3 flex-col" v-else></div>
                                        <span class="info11">无需发票</span>
                                   </div>

                                   <div class="box2g flex_row_start_center" @click="checkRadio('invoice', 2)">
                                        <img src="@/assets/inquiry/inq_icon5.png" v-if="inqDTO.invoiceType == 2" />
                                        <div class="box3 flex-col" v-else></div>
                                        <span class="info11">增值税专用发票</span>
                                   </div>

                                   <div class="box2g flex_row_start_center" @click="checkRadio('invoice', 3)">
                                        <img src="@/assets/inquiry/inq_icon5.png" v-if="inqDTO.invoiceType == 3" />
                                        <div class="box3 flex-col" v-else></div>
                                        <span class="info11">增值税普通发票</span>
                                   </div>


                              </div>
                              <div class="outer25 flex-row justify-between">
                                   <div class="layer10 flex-col"></div>
                                   <span class="word20">联系人信息</span>
                              </div>
                              <div class="outer26 flex_row_start_start">
                                   <div class="layer11 flex_row_start_start">
                                        <div style="margin-top: 8px;">
                                             <span class="word21">*</span>
                                             <span class="info15">联系人姓名：</span>
                                        </div>
                                        <div class="container2">
                                             <div :class="{ validate: validate.receiverName }">
                                                  <div class="layer12 flex-col vBorder">
                                                       <input type="text" v-model="inqDTO.receiverName"
                                                            @input="inputEvent('receiverName')" maxlength="10">
                                                  </div>
                                                  <span class="error_msg">{{ validate.receiverName }}</span>
                                             </div>
                                        </div>
                                   </div>
                                   <div class="layer13 flex_row_start_start">
                                        <div style="margin-top: 9px;">
                                             <span class="word22">*</span>
                                             <span class="txt13">手机号：&nbsp;</span>
                                        </div>
                                        <div style="margin-left: 9px;">
                                             <div :class="{ validate: validate.receiverMobile }">
                                                  <div class="layer14 flex-col vBorder">
                                                       <input type="text" v-model="inqDTO.receiverMobile"
                                                            @input="inputEvent('receiverMobile')" maxlength="11">
                                                  </div>
                                                  <span class="error_msg">{{ validate.receiverMobile }}</span>
                                             </div>

                                        </div>

                                   </div>
                              </div>
                              <div class="outer27 flex_row_start_center">
                                   <div class="section8">
                                        <span class="txt14">*</span> <span class="word24"></span>
                                        <span class="info17">收货地址：</span>
                                        <span class="word25">&nbsp;&nbsp;</span>
                                   </div>
                                   <div :class="{ validate: validate.receiverAddress }">
                                        <div class="section9 flex-col vBorder">
                                             <el-cascader ref="cascaderAddr" v-model="inqDTO.receiverAddress"
                                                  :options="areaDataArray" placeholder="请选择所在地区" :props="cascaderProps"
                                                  size="small" clearable @change="inputEvent('receiverAddress')">
                                             </el-cascader>
                                        </div>
                                        <span class="error_msg">{{ validate.receiverAddress }}</span>
                                   </div>
                              </div>
                              <div class="outer28 flex_row_start_center">
                                   <div class="section10 flex_row_start_center">
                                        <div>
                                             <span class="info18">*</span> <span class="info19"></span>
                                             <span class="word26">详细地址：&nbsp;&nbsp;</span>
                                        </div>
                                        <div :class="{ validate: validate.receiverAddressDetail }">
                                             <div class="section11 flex_row_start_center vBorder">
                                                  <input type="text" v-model="inqDTO.receiverAddressDetail"
                                                       @input="inputEvent('receiverAddressDetail')" maxlength="50">
                                                  <span class="word27">{{ inqDTO.receiverAddressDetail.length }}/50</span>
                                             </div>
                                             <span class="error_msg">{{ validate.receiverAddressDetail }}</span>
                                        </div>

                                   </div>

                              </div>
                              <div class="outer29 flex-col" @click="initiate_inquiry">
                                   <span class="word28">确认{{ route.query.inquireId ? '编辑' : '发布' }}</span>
                              </div>
                         </div>

                    </div>
               </div>
          </div>
     </div>
</template>

<script>
import { ref, reactive, getCurrentInstance, onMounted } from 'vue'
import { inqUtils } from '@/utils/inquiry_utils.js'
import areaData from "@/assets/area.json";
import { ElMessage } from 'element-plus'
import { useRouter, useRoute } from 'vue-router'
export default {
     setup() {
          const header_bg = require('@/assets/inquiry/header_bg3.png')
          const { proxy } = getCurrentInstance()
          const value = ref('')

          const { uploadFile, fileList, clearFileList, unitList, formatTime, setFileDTO } = inqUtils()
          const { areaDataArray, cascaderProps, disabledDate } = inqUtils();

          const router = useRouter()
          const route = useRoute()

          const productListDTO = {
               brandName: '',
               buyNum: 1,
               priceMax: '',
               priceMin: '',
               productName: '',
               spec: '',
               unit: '',
               unitName: unitList.value[0],
          }

          const inqDTO = reactive({
               title: '',
               billType: 1,
               billTypeName: '定金发货',
               deliverType: 1,
               deliverTypeName: '买方自提',
               description: '',
               expectedDeliverDate: '',
               inquireId: '',
               invoiceType: 1,
               priceEndTime: '',
               productList: [JSON.parse(JSON.stringify(productListDTO))],
               receiverAddress: '',
               receiverAddressDetail: '',
               receiverMobile: '',
               receiverName: '',
               referenceFile: ''
          })

          const validate = reactive({
               productName: { index: -1, msg: '' },
               price: { index: -1, msg: '' },
               buyNum: { index: -1, msg: '' },
               unit: { index: -1, msg: '' },
               expectedDeliverDate: '',
               priceEndTime: '',
               receiverAddress: '',
               receiverAddressDetail: '',
               receiverMobile: '',
               receiverName: '',
               title: '',
               deliverTypeName: '',
               billTypeName: ''
          })


          const addProductPanel = () => {
               inqDTO.productList.push(JSON.parse(JSON.stringify(productListDTO)))
          }

          const removeProPanel = (proIdx) => {
               inqDTO.productList.splice(proIdx, 1)
          }

          const changeFile = (e) => {
               if (fileList.length >= 3) {
                    ElMessage('最多上传三个附件')
                    return
               }
               uploadFile(e, () => {
                    proxy.$refs.inputTab.value = ''
               })
          }

          const blurEvent = (obj, type) => {
               if (type == 'priceMin' || type == 'priceMax') {
                    if (obj[type] > 9999999)
                         obj[type] = 9999999
               }

               if (type == 'buyNum') {
                    if (obj[type] > 99999)
                         obj[type] = 99999
               }
          }

          const inputEvent = (val, index) => {
               if (index != undefined) {
                    validate[val].index = -1
                    validate[val].msg = ''
               } else {
                    validate[val] = ''
               }
          }

          const checkRadio = (checkType, value, valueName) => {
               switch (checkType) {
                    case 'deliver': {
                         inqDTO.deliverType = value
                         inqDTO.deliverTypeName = valueName ? valueName : ''
                         break
                    }
                    case 'bill': {
                         inqDTO.billType = value
                         inqDTO.billTypeName = valueName ? valueName : ''
                         break
                    }
                    case 'invoice': {
                         inqDTO.invoiceType = value
                         break
                    }
               }
          }

          const checkInputEvent = () => {

               let {
                    title,
                    expectedDeliverDate,
                    priceEndTime,
                    receiverAddressDetail,
                    receiverMobile,
                    receiverName,
                    receiverAddress,
                    productList,
                    deliverTypeName,
                    billTypeName
               } = inqDTO

               if (!title) {
                    validate.title = '请输入询盘标题'
                    // location.href = '#title'
                    intoView('#title')

                    return false
               }



               for (let i = 0; i < productList.length; i++) {
                    if (!productList[i].productName) {
                         validate.productName.index = i
                         validate.productName.msgTitle = '请输入产品名称'
                         intoView(`#rowtab${i}`)


                         return false
                    }

                    if (!productList[i].buyNum) {
                         validate.buyNum.index = i
                         validate.buyNum.msgTitle = '请输入产品数量'
                         intoView(`#rowtab${i}`)


                         return false
                    } else if (productList[i].buyNum <= 0) {
                         validate.buyNum.index = i
                         validate.buyNum.msg = '产品数量必须为大于0的正数'
                         intoView(`#rowtab${i}`)


                         return false
                    } else if (!/^[1-9]\d*$/.test(productList[i].buyNum)) {
                         validate.buyNum.index = i
                         validate.buyNum.msg = '产品数量必须正整数'
                         intoView(`#rowtab${i}`)


                         return false
                    }

                    if (productList[i].unitName == '其他' && !productList[i].unit) {
                         validate.unit.index = i
                         validate.unit.msg = '请填写单位'
                         intoView(`#rowtab${i}`)


                         return false
                    }

                    if (!productList[i].priceMin && !productList[i].priceMax) {
                         validate.price.index = i
                         validate.price.msg = '请填写期望单价'
                         intoView(`#rowtab${i}`)


                         return false
                    } else if (Number(productList[i].priceMin) <= 0 || Number(productList[i].priceMax) <= 0) {
                         validate.price.index = i
                         validate.price.msg = '期望单价必须为大于0的正数'
                         intoView(`#rowtab${i}`)


                         return false
                    } else if (Number(productList[i].priceMin) > Number(productList[i].priceMax)) {
                         validate.price.index = i
                         validate.price.msg = '期望单价的最大值不能小于最小值'
                         intoView(`#rowtab${i}`)


                         return false
                    }
               }

               if (!expectedDeliverDate) {
                    validate.expectedDeliverDate = '请选择报价截止日期'
                    // location.href = `#expectedDeliverDate`
                    intoView(`#expectedDeliverDate`)
                    return false
               }

               if (!deliverTypeName) {
                    validate.deliverTypeName = '请填写配送方式'
                    return false
               }

               if (!billTypeName) {
                    validate.billTypeName = '请填写结算方式'
                    return false
               }

               if (!priceEndTime) {
                    validate.priceEndTime = '请选择期望收货日期'
                    // location.href = `#priceEndTime`
                    intoView(`#priceEndTime`)

                    return false
               }
               if (!receiverName) {
                    validate.receiverName = '请输入联系人姓名'
                    return false
               }
               if (!receiverMobile) {
                    validate.receiverMobile = '请输入手机号'
                    return false
               }
               if (!receiverAddress.length) {
                    validate.receiverAddress = '请选择收货地址'
                    return false
               }
               if (!receiverAddressDetail) {
                    validate.receiverAddressDetail = '请填写详细地址'
                    return false
               }
               if (proxy.$checkPhone(receiverMobile) != true) {
                    validate.receiverMobile = '格式错误，请输入正确的手机号码'
                    return false
               }

               return true
          }


          const intoView = (selector) => {
               document.querySelector(selector).scrollIntoView(
                    {
                         // 定义动画过渡效果， "auto"或 "smooth" 之一。默认为 "auto"
                         behavior: "smooth",
                         // 定义垂直方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "start"
                         block: "center",
                         // 定义水平方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "nearest"
                         inline: "nearest"
                    }
               )

          }


          const getEditDetail = () => {
               proxy.$get('v3/business/front/purchaseInquire/detail', {
                    inquireId: route.query.inquireId
               }).then(res => {
                    if (res.state == 200) {
                         Object.keys(res.data).forEach(item => {
                              if (item == 'receiverAddress') {
                                   inqDTO[item] = res.data[item].split('/')
                              } else if (item == 'referenceFile' && res.data[item]) {
                                   fileList.value = res.data[item].split(',').map(file => {
                                        return setFileDTO({ path: file, url: file })
                                   })
                              } else {
                                   inqDTO[item] = res.data[item]
                              }
                         })
                    }
               })
          }


          const initiate_inquiry = () => {
               if (!checkInputEvent()) {
                    return
               }

               inqDTO.expectedDeliverDate = formatTime(inqDTO.expectedDeliverDate)
               inqDTO.priceEndTime = formatTime(inqDTO.priceEndTime)
               inqDTO.receiverAddress = inqDTO.receiverAddress.join('/')
               inqDTO.referenceFile = fileList.value.map(i => i.path).join(',')
               inqDTO.productList.map(item => {
                    if (item.unitName == '其他') {
                         item.unitName = item.unit
                         delete item.unit
                    }

                    item.priceMax = Number(item.priceMax).toFixed(2)
                    item.priceMin = Number(item.priceMin).toFixed(2)
               })

               let url
               if (route.query.from && route.query.inquireId) {
                    url = 'v3/business/front/purchaseInquire/update'
               } else {
                    url = 'v3/business/front/purchaseInquire/add'
               }


               proxy.$post(url, inqDTO, 'json').then(res => {
                    if (res.state == 200) {
                         ElMessage.success(res.msg)
                         setTimeout(() => {
                              router.push('/member/inquiry/myInq')
                         }, 500)
                    } else {
                         inqDTO.receiverAddress = inqDTO.receiverAddress.split('/')
                         ElMessage.error(res.msg)
                    }
               })


          }



          onMounted(() => {
               if (route.query.from && route.query.inquireId) {
                    getEditDetail()
               }
          })



          return {
               header_bg,
               inqDTO,
               addProductPanel,
               removeProPanel,
               validate,
               checkRadio,
               changeFile,
               fileList,
               clearFileList,
               inputEvent,
               initiate_inquiry,
               cascaderProps,
               areaDataArray,
               unitList,
               route,
               disabledDate,
               blurEvent
          }

     }
}
</script>

<style lang="scss">
.section9 {
     .el-input__inner {
          border: none;
     }
}

.block3 {
     .el-input__inner {
          border: none;
          padding-left: 4px;
          padding-right: 10px;
     }

     .el-input__icon {
          width: 20px;
     }

     .el-input__icon {
          right: 2px;
     }
}
</style>

<style lang="scss" scoped>
.inquiry_header {
     position: relative;
     height: 300px;

     .header_bg {
          background-position: center center;
          width: 100%;
          height: 301px;
          position: absolute;
          left: 0;
          top: 0;
          /* margin-left: -960px; */
          background-repeat: no-repeat;
          background-size: cover;
     }
}

#upload {
     display: none;
}

.validate {
     .vBorder {
          border-color: #F30606 !important;
     }

     .error_msg {
          display: block;
          margin-top: 2px;
     }
}

.error_msg {
     font-size: 12px;
     font-family: Source Han Sans CN;
     font-weight: 400;
     color: #F30606;
     display: none;
}

/*** 消除input元素 type="number" 时默认的 加减按钮*/
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
     -webkit-appearance: none;
     margin: 0;
}

/*** 消除input元素 type="number" 时默认的 加减按钮---moz版*/
input[type="number"] {
     -moz-appearance: textfield;
}

input {
     border: none;
     outline: none;
     height: 32px;
}

.initiate_inquiry_z {
     background: #F5F5F5;
     padding-top: 20px;
     width: 100%;
     padding-bottom: 30px;

     .init_main {
          width: 1200px;
          background: #FFFFFF;
          margin: 0 auto;

          .bd1 {
               background-color: rgba(255, 255, 255, 1);
               z-index: 8;
               position: relative;
               padding: 20px;

               .bd2 {

                    .outer1 {
                         width: 81px;
                         height: 18px;
                         margin-left: 1px;

                         .layer1 {
                              background-color: rgba(40, 95, 222, 1);
                              width: 6px;
                              height: 18px;
                         }

                         .info1 {
                              color: rgba(18, 18, 18, 1);
                              font-size: 16px;
                              margin-left: 10px;
                         }
                    }

                    .outer2 {
                         width: 1158px;
                         margin: 20px 0 0 3px;

                         .bd3 {
                              margin-top: 12px;

                              .bd31 {
                                   margin-top: 10px;
                              }

                              .word2 {
                                   color: rgba(240, 2, 2, 1);
                                   font-size: 14px;
                                   line-height: 14px;
                              }

                              .info2 {

                                   color: rgba(51, 51, 51, 1);
                                   font-size: 14px;
                                   line-height: 14px;
                              }
                         }

                         .bd4 {
                              background-color: rgba(255, 255, 255, 1);
                              height: 36px;
                              border: 1px solid rgba(201, 201, 201, 1);
                              width: 1070px;
                              padding: 0 10px;

                              input {
                                   flex: 1;
                              }

                              .word3 {
                                   color: rgba(153, 153, 153, 1);
                                   font-size: 12px;

                              }
                         }
                    }

                    .info3 {
                         color: rgba(18, 18, 18, 1);
                         font-size: 14px;
                         margin: 30px 0 0 1px;
                    }

                    .outer3 {
                         margin-top: 13px;
                    }


                    .outer5 {
                         border-radius: 4px;
                         height: 35px;
                         border: 1px dashed rgba(40, 95, 222, 1);
                         width: 150px;
                         margin: 24px 0 0 505px;
                         cursor: pointer;
                         text-align: center;

                         .info6 {
                              line-height: 35px;
                              color: rgba(40, 95, 222, 1);
                              font-size: 14px;
                              font-family: SourceHanSansCN-Regular;

                         }
                    }

                    .outer6g {
                         margin: 30px 0 0 1px;

                         .section1g {
                              margin-top: 11px;

                              .word6g {
                                   color: rgba(240, 2, 2, 1);
                                   font-size: 14px;
                              }

                              .word7g {
                                   color: rgba(51, 51, 51, 1);
                                   font-size: 14px;
                              }
                         }

                         .section2g {
                              background-color: rgba(255, 255, 255, 1);
                              margin-left: 11px;

                              .section21g {
                                   border: 1px solid #fff;
                              }

                              .el-input__inner {
                                   border: unset;
                              }
                         }

                         .section3g {
                              margin: 11px 0 0 45px;

                              .word8g {
                                   color: rgba(240, 2, 2, 1);
                                   font-size: 14px;
                              }

                              .word9g {
                                   font-size: 14px;
                                   color: rgba(51, 51, 51, 1);
                              }
                         }


                    }

                    .outer8 {
                         margin: 22px 0 0 12px;

                         .info8 {
                              width: 92px;
                              height: 14px;
                              color: rgba(51, 51, 51, 1);
                              font-size: 14px;
                              font-family: SourceHanSansCN-Regular;
                              text-align: left;
                              white-space: nowrap;
                              line-height: 14px;
                              margin-top: 7px;
                              display: block;
                         }

                         .outer9 {
                              background-color: rgba(255, 255, 255, 1);
                              border-radius: 50%;
                              height: 18px;
                              border: 1px solid rgba(40, 95, 222, 1);
                              width: 18px;
                              margin: 5px 0 0 15px;

                              .group1 {
                                   background-color: rgba(40, 95, 222, 1);
                                   border-radius: 50%;
                                   width: 10px;
                                   height: 10px;
                                   margin: 4px 0 0 4px;
                              }
                         }

                         .txt2 {

                              color: rgba(51, 51, 51, 1);
                              font-size: 14px;
                              display: block;
                              margin: 0px 0 0 8px;
                         }

                         .outer10 {
                              background-color: rgba(255, 255, 255, 1);
                              border-radius: 50%;
                              width: 18px;
                              height: 18px;
                              border: 2px solid rgba(201, 201, 201, 1);
                         }

                         .word11 {
                              width: 58px;
                              height: 14px;
                              color: rgba(51, 51, 51, 1);
                              font-size: 14px;
                              font-family: SourceHanSansCN-Regular;
                              text-align: left;
                              white-space: nowrap;
                              line-height: 14px;
                              display: block;
                              margin: 7px 0 0 9px;
                         }

                         .outer11 {
                              background-color: rgba(255, 255, 255, 1);
                              border-radius: 50%;
                              width: 18px;
                              height: 18px;
                              border: 2px solid rgba(201, 201, 201, 1);
                              margin: 5px 0 0 35px;
                         }

                         .word12 {
                              width: 28px;
                              height: 13px;
                              color: rgba(51, 51, 51, 1);
                              font-size: 14px;
                              font-family: SourceHanSansCN-Regular;
                              text-align: left;
                              white-space: nowrap;
                              line-height: 14px;
                              display: block;
                              margin: 7px 0 0 9px;
                         }

                         .outer12 {
                              padding: 0 5px;
                              width: 100px;
                              height: 28px;
                              border: 1px solid rgba(201, 201, 201, 1);

                              &.disable_input {
                                   background-color: rgba(201, 201, 201, 1);
                              }
                         }
                    }

                    .outer13 {
                         width: 1120px;
                         height: 164px;
                         margin: 24px 0 0 38px;

                         .word13 {
                              width: 63px;
                              height: 14px;
                              color: rgba(51, 51, 51, 1);
                              font-size: 14px;
                              font-family: SourceHanSansCN-Regular;
                              text-align: left;
                              white-space: nowrap;
                              line-height: 14px;
                              margin-top: 7px;
                              display: block;
                              margin-right: 2px;
                         }

                         .bd5 {
                              background-color: rgba(255, 255, 255, 1);
                              height: 164px;
                              border: 1px solid rgba(201, 201, 201, 1);
                              width: 1046px;
                              justify-content: flex-center;

                              .section5 {
                                   margin: 9px 0 0 10px;

                                   textarea {
                                        width: 100%;
                                        resize: none;
                                        color: rgba(153, 153, 153, 1);
                                        border: none;
                                        outline: none;
                                   }
                              }

                              .section6 {

                                   align-self: flex-end;
                                   margin-right: 10px;
                                   margin-bottom: 10px;

                                   .word14 {
                                        width: 34px;
                                        height: 13px;
                                        color: rgba(153, 153, 153, 1);
                                        font-size: 12px;
                                        font-family: SourceHanSansCN-Regular;
                                        text-align: left;
                                        white-space: nowrap;
                                        line-height: 13px;
                                        display: block;
                                   }
                              }
                         }
                    }

                    .outer14 {
                         width: 618px;
                         height: 35px;
                         margin: 30px 0 0 39px;

                         .txt3 {
                              width: 63px;
                              height: 15px;
                              color: rgba(51, 51, 51, 1);
                              font-size: 14px;
                              font-family: SourceHanSansCN-Regular;
                              text-align: left;
                              white-space: nowrap;
                              line-height: 15px;
                              margin-top: 10px;
                              display: block;
                              margin-right: 4px
                         }

                         .disabledUpload {
                              position: relative;

                              &:after {
                                   content: '';
                                   position: absolute;
                                   top: 0;
                                   left: 0;
                                   right: 0;
                                   bottom: 0;
                                   opacity: 0.3;
                              }



                              .outer15 {
                                   border: 1px dashed #999;
                                   color: #999;

                                   .info10 {
                                        color: #999;
                                   }
                              }
                         }

                         .outer15 {
                              border-radius: 4px;
                              height: 35px;
                              border: 1px dashed rgba(40, 95, 222, 1);
                              width: 150px;
                              cursor: pointer;

                              .info10 {
                                   width: 70px;
                                   height: 14px;
                                   color: rgba(40, 95, 222, 1);
                                   font-size: 14px;
                                   font-family: SourceHanSansCN-Regular;
                                   text-align: left;
                                   white-space: nowrap;
                                   line-height: 14px;
                                   display: block;
                                   margin: 10px 0 0 40px;
                              }
                         }

                         .word15 {
                              width: 384px;
                              height: 14px;
                              color: rgba(153, 153, 153, 1);
                              font-size: 12px;
                              font-family: SourceHanSansCN-Regular;
                              text-align: left;
                              white-space: nowrap;
                              line-height: 14px;
                              margin-top: 11px;
                              display: block;
                         }
                    }

                    .outer16 {
                         width: 165px;
                         height: 31px;
                         margin: 21px 0 0 115px;

                         .label5 {
                              width: 31px;
                              height: 31px;
                         }

                         .word16 {
                              width: 71px;
                              height: 14px;
                              color: rgba(51, 51, 51, 1);
                              font-size: 12px;
                              font-family: SourceHanSansCN-Regular;
                              text-align: left;
                              white-space: nowrap;
                              line-height: 14px;
                              display: block;
                              margin: 6px 0 0 14px;
                         }

                         .txt4 {
                              width: 25px;
                              height: 12px;
                              color: rgba(40, 95, 222, 1);
                              font-size: 12px;
                              font-family: SourceHanSansCN-Regular;
                              text-align: left;
                              white-space: nowrap;
                              line-height: 12px;
                              display: block;
                              margin: 7px 0 0 24px;
                              cursor: pointer;

                              &:hover {
                                   text-decoration: underline;
                              }
                         }
                    }


                    .outer18 {
                         width: 81px;
                         height: 18px;
                         margin: 30px 0 0 1px;

                         .group2 {
                              background-color: rgba(40, 95, 222, 1);
                              width: 6px;
                              height: 18px;
                         }

                         .txt7 {
                              color: rgba(18, 18, 18, 1);
                              font-size: 16px;
                              margin-left: 10px;
                         }
                    }

                    .outer19 {

                         margin: 17px 0 0 17px;

                         .box1 {
                              width: 74px;
                              height: 14px;
                              font-size: 0;
                              font-family: SourceHanSansCN-Regular;
                              text-align: left;
                              white-space: nowrap;
                              line-height: 14px;

                              .txt8 {
                                   width: 74px;
                                   height: 14px;
                                   color: rgba(240, 2, 2, 1);
                                   font-size: 14px;
                                   font-family: SourceHanSansCN-Regular;
                                   text-align: left;
                                   white-space: nowrap;
                                   line-height: 14px;
                              }

                              .txt9 {
                                   width: 74px;
                                   height: 14px;
                                   color: rgba(51, 51, 51, 1);
                                   font-size: 14px;
                                   font-family: SourceHanSansCN-Regular;
                                   text-align: left;
                                   white-space: nowrap;
                                   line-height: 14px;
                              }
                         }

                         .box2g {
                              margin-left: 23px;

                              img {
                                   width: 18px;
                                   height: 18px;
                              }

                              .box3 {
                                   background-color: rgba(255, 255, 255, 1);
                                   border-radius: 50%;
                                   width: 18px;
                                   height: 18px;
                                   border: 2px solid rgba(201, 201, 201, 1);
                              }

                              .info11 {

                                   color: rgba(51, 51, 51, 1);
                                   font-size: 14px;

                                   margin: 0px 0 0 8px;
                              }
                         }

                         .word17 {
                              width: 57px;
                              height: 14px;
                              color: rgba(51, 51, 51, 1);
                              font-size: 14px;
                              font-family: SourceHanSansCN-Regular;
                              text-align: left;
                              white-space: nowrap;
                              line-height: 14px;
                              display: block;
                              margin: 7px 0 0 9px;
                         }

                         .box4 {
                              background-color: rgba(255, 255, 255, 1);
                              border-radius: 50%;
                              width: 18px;
                              height: 18px;
                              border: 2px solid rgba(201, 201, 201, 1);
                              margin: 5px 0 0 39px;
                         }

                         .word18 {
                              width: 57px;
                              height: 14px;
                              color: rgba(51, 51, 51, 1);
                              font-size: 14px;
                              font-family: SourceHanSansCN-Regular;
                              text-align: left;
                              white-space: nowrap;
                              line-height: 14px;
                              display: block;
                              margin: 7px 0 0 9px;
                         }

                         .box5 {
                              background-color: rgba(255, 255, 255, 1);
                              border-radius: 50%;
                              width: 18px;
                              height: 18px;
                              border: 2px solid rgba(201, 201, 201, 1);
                              margin: 5px 0 0 43px;
                         }

                         .info12 {
                              width: 28px;
                              height: 13px;
                              color: rgba(51, 51, 51, 1);
                              font-size: 14px;
                              font-family: SourceHanSansCN-Regular;
                              text-align: left;
                              white-space: nowrap;
                              line-height: 14px;
                              display: block;
                              margin: 7px 0 0 9px;
                         }

                         .box6 {
                              width: 68px;
                              height: 28px;
                              border: 1px solid rgba(201, 201, 201, 1);
                              margin-left: 13px;
                              padding: 0 5px;

                              &.disable_input {
                                   background-color: rgba(201, 201, 201, 1);
                              }
                         }
                    }

                    .outer20 {
                         width: 508px;
                         height: 18px;
                         margin: 16px 0 0 17px;

                         .box2g {
                              margin-left: 23px;

                              img {
                                   width: 18px;
                                   height: 18px;
                              }

                              .box3 {
                                   background-color: rgba(255, 255, 255, 1);
                                   border-radius: 50%;
                                   width: 18px;
                                   height: 18px;
                                   border: 2px solid rgba(201, 201, 201, 1);
                              }

                              .info11 {

                                   color: rgba(51, 51, 51, 1);
                                   font-size: 14px;

                                   margin: 0px 0 0 8px;
                              }
                         }

                         .box1 {
                              width: 74px;
                              height: 14px;
                              font-size: 0;
                              font-family: SourceHanSansCN-Regular;
                              text-align: left;
                              white-space: nowrap;
                              line-height: 14px;

                              .txt8 {
                                   width: 74px;
                                   height: 14px;
                                   color: rgba(240, 2, 2, 1);
                                   font-size: 14px;
                                   font-family: SourceHanSansCN-Regular;
                                   text-align: left;
                                   white-space: nowrap;
                                   line-height: 14px;
                              }

                              .txt9 {
                                   width: 74px;
                                   height: 14px;
                                   color: rgba(51, 51, 51, 1);
                                   font-size: 14px;
                                   font-family: SourceHanSansCN-Regular;
                                   text-align: left;
                                   white-space: nowrap;
                                   line-height: 14px;
                              }
                         }

                    }

                    .outer25 {
                         width: 98px;
                         height: 18px;
                         margin: 32px 0 0 1px;

                         .layer10 {
                              background-color: rgba(40, 95, 222, 1);
                              width: 6px;
                              height: 18px;
                         }

                         .word20 {
                              color: rgba(18, 18, 18, 1);
                              font-size: 16px;
                              margin-left: 10px;
                         }
                    }

                    .outer26 {

                         margin: 24px 0 0 16px;

                         .layer11 {

                              .word21 {
                                   color: rgba(230, 0, 18, 1);
                                   font-size: 14px;
                              }

                              .info15 {

                                   color: rgba(51, 51, 51, 1);
                                   font-size: 14px;

                              }

                              .info16 {
                                   width: 89px;
                                   height: 14px;
                                   color: rgba(102, 102, 102, 1);
                                   font-size: 14px;
                                   font-family: SourceHanSansCN-Regular;
                                   text-align: left;
                                   white-space: nowrap;
                                   line-height: 14px;
                              }
                         }

                         .container2 {
                              margin-left: 4px;

                         }

                         .layer12 {
                              width: 200px;
                              height: 32px;
                              border: 1px solid rgba(201, 201, 201, 1);
                              padding: 0 10px;
                         }

                         .layer13 {

                              margin: 0px 0 0 25px;

                              .word22 {

                                   color: rgba(230, 0, 18, 1);
                                   font-size: 14px;
                                   line-height: 14px;
                              }

                              .txt12 {
                                   width: 60px;
                                   height: 14px;
                                   color: rgba(102, 102, 102, 1);
                                   font-size: 14px;
                                   font-family: SourceHanSansCN-Regular;
                                   text-align: left;
                                   white-space: nowrap;
                                   line-height: 14px;
                              }

                              .txt13 {
                                   color: rgba(51, 51, 51, 1);
                                   font-size: 14px;
                                   font-family: SourceHanSansCN-Regular;
                                   text-align: left;
                                   white-space: nowrap;
                                   line-height: 14px;
                              }

                              .word23 {
                                   width: 60px;
                                   height: 14px;
                                   color: rgba(102, 102, 102, 1);
                                   font-size: 14px;
                                   font-family: SourceHanSansCN-Regular;
                                   text-align: left;
                                   white-space: nowrap;
                                   line-height: 14px;
                              }
                         }

                         .layer14 {
                              width: 200px;
                              height: 32px;
                              border: 1px solid rgba(201, 201, 201, 1);
                              padding: 0 10px;
                         }
                    }

                    .outer27 {
                         margin: 20px 0 0 22px;

                         .section8 {

                              font-family: SourceHanSansCN-Regular;
                              text-align: left;
                              white-space: nowrap;

                              .txt14 {

                                   color: rgba(230, 0, 18, 1);
                                   font-size: 14px;
                              }

                              .word24 {

                                   color: rgba(102, 102, 102, 1);
                                   font-size: 14px;
                                   text-align: left;
                                   line-height: 14px;
                              }

                              .info17 {

                                   color: rgba(51, 51, 51, 1);
                                   font-size: 14px;
                              }

                              .word25 {
                                   width: 74px;
                                   height: 14px;
                                   color: rgba(102, 102, 102, 1);
                                   font-size: 14px;
                                   font-family: SourceHanSansCN-Regular;
                                   text-align: left;
                                   white-space: nowrap;
                                   line-height: 14px;
                              }
                         }

                         .section9 {
                              height: 35px;
                              border: 1px solid rgba(201, 201, 201, 1);
                              width: 494px;

                              .layer15 {
                                   width: 474px;
                                   height: 12px;
                                   margin: 10px 0 0 10px;

                                   .txt15 {
                                        width: 87px;
                                        height: 12px;
                                        color: rgba(153, 153, 153, 1);
                                        font-size: 12px;
                                        font-family: SourceHanSansCN-Regular;
                                        text-align: left;
                                        white-space: nowrap;
                                        line-height: 12px;
                                        display: block;
                                   }

                                   .label7 {
                                        width: 13px;
                                        height: 7px;
                                        margin-top: 3px;
                                   }
                              }
                         }
                    }

                    .outer28 {
                         width: 1146px;
                         margin: 20px 0 0 22px;

                         .section10 {

                              margin-top: 9px;

                              .info18 {
                                   color: rgba(230, 0, 18, 1);
                                   font-size: 14px;
                              }

                              .info19 {
                                   color: rgba(102, 102, 102, 1);
                                   font-size: 14px;
                              }

                              .word26 {

                                   color: rgba(51, 51, 51, 1);
                                   font-size: 14px;
                              }
                         }

                         .section11 {
                              height: 34px;
                              border: 1px solid rgba(201, 201, 201, 1);
                              width: 1047px;
                              padding-left: 10px;

                              input {
                                   flex: 1;
                              }

                              .word27 {

                                   color: rgba(153, 153, 153, 1);
                                   font-size: 12px;
                                   margin-right: 10px;
                              }
                         }
                    }

                    .outer29 {
                         background-color: rgba(40, 95, 222, 1);
                         border-radius: 6px;
                         height: 42px;
                         width: 200px;
                         margin: 57px 0 0 481px;
                         cursor: pointer;

                         .word28 {
                              width: 65px;
                              height: 16px;
                              color: rgba(255, 255, 255, 1);
                              font-size: 16px;
                              font-family: SourceHanSansCN-Regular;
                              text-align: left;
                              white-space: nowrap;
                              line-height: 16px;
                              display: block;
                              margin: 13px 0 0 68px;
                         }
                    }
               }

               .bd6 {
                    z-index: 18;
                    border: 1px solid rgba(220, 220, 220, 1);

                    .outer30 {
                         .block2 {
                              padding-left: 69px;
                              background-color: rgba(247, 248, 250, 1);
                              height: 45px;
                              border-bottom: 1px solid gainsboro;

                              .main1 {
                                   line-height: 45px;
                                   font-size: 14px;
                                   font-family: SourceHanSansCN-Regular;

                                   .word29 {
                                        color: rgba(230, 0, 18, 1);
                                        font-size: 14px;
                                   }

                                   .word30 {
                                        color: rgba(51, 51, 51, 1);
                                        font-size: 14px;
                                   }
                              }

                              .word31 {
                                   color: rgba(51, 51, 51, 1);
                                   font-size: 14px;
                                   line-height: 45px;
                                   margin-left: 170px;
                              }

                              .txt16 {
                                   color: rgba(51, 51, 51, 1);
                                   font-size: 14px;
                                   line-height: 45px;
                                   margin-left: 170px;
                              }

                              .main2 {
                                   line-height: 45px;
                                   margin-left: 170px;

                                   .info20 {
                                        color: rgba(230, 0, 18, 1);
                                        font-size: 14px;
                                   }

                                   .word32 {
                                        color: rgba(51, 51, 51, 1);
                                        font-size: 14px;
                                   }
                              }

                              .main3 {
                                   line-height: 45px;
                                   margin-left: 170px;

                                   .word33 {
                                        color: rgba(230, 0, 18, 1);
                                        font-size: 14px;
                                   }

                                   .word34 {

                                        color: rgba(51, 51, 51, 1);
                                        font-size: 14px;

                                   }
                              }
                         }

                         .block3 {
                              background: #fff;
                              border-bottom: 1px solid gainsboro;

                              .layer16 {
                                   margin: 15px 0 15px 31px;

                                   .section12 {
                                        background-color: rgba(255, 255, 255, 1);
                                        width: 170px;
                                        height: 36px;
                                        border: 1px solid rgba(201, 201, 201, 1);
                                        padding: 0 10px;
                                   }

                                   .section13 {
                                        background-color: rgba(255, 255, 255, 1);
                                        width: 170px;
                                        height: 36px;
                                        border: 1px solid rgba(201, 201, 201, 1);
                                        margin-left: 46px;
                                        padding: 0 10px;
                                   }



                                   .section15 {
                                        background-color: rgba(255, 255, 255, 1);
                                        height: 36px;
                                        border: 1px solid rgba(201, 201, 201, 1);
                                        width: 130px;
                                        padding-left: 5px;

                                        .layer17 {
                                             height: 36px;
                                             position: relative;

                                             input {
                                                  width: 55px;
                                             }

                                             .icon2 {
                                                  width: 1px;
                                                  height: 36px;
                                             }

                                             .con1 {
                                                  height: 34px;
                                                  border-left: 1px solid #c9c9c9;
                                             }

                                             .info21 {
                                                  margin-left: 5px;
                                                  color: rgba(51, 51, 51, 1);
                                                  font-size: 12px;
                                             }

                                             .layer18 {
                                                  position: absolute;
                                                  width: 60px;
                                                  top: -1px;
                                                  right: -60px;
                                                  height: 36px;
                                                  border: 1px solid #c9c9c9;

                                             }

                                             .label8 {
                                                  width: 13px;
                                                  height: 7px;
                                                  margin-left: 6px;
                                             }
                                        }
                                   }

                                   .container1 {
                                        margin: 1px 0 0 79px;

                                   }

                                   .section16 {
                                        background-color: rgba(255, 255, 255, 1);
                                        height: 36px;
                                        border: 1px solid rgba(201, 201, 201, 1);
                                        width: 95px;
                                        padding: 0 10px;
                                   }

                                   .info22 {
                                        color: rgba(51, 51, 51, 1);
                                        font-size: 24px;
                                        font-family: SourceHanSansCN-Regular;
                                        text-align: left;
                                        white-space: nowrap;
                                        line-height: 24px;
                                        display: block;
                                        margin: 5px;
                                   }

                                   .section17 {
                                        background-color: rgba(255, 255, 255, 1);
                                        height: 36px;
                                        border: 1px solid rgba(201, 201, 201, 1);
                                        width: 96px;
                                        padding: 0 10px;
                                   }

                                   .section18 {
                                        margin-left: 15px;
                                        display: flex;
                                        align-items: center;
                                        cursor: pointer;

                                        img {
                                             width: 18px;
                                             height: 18px;
                                        }
                                   }
                              }
                         }
                    }
               }
          }
     }
}
</style>